import { Component, OnDestroy, OnInit } from '@angular/core';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { forkJoin, Observable, Subject, takeUntil } from 'rxjs';
import {AppInsightsService} from 'src/app/helpers/AppInsights';
import {
  defaultFilters,
  sortEventsByFilter,
} from 'src/app/helpers/EventHelpers';
import { crmikpk_participantstatus } from 'src/app/models/crmikpk_participantstatus';
import { CacheService } from 'src/app/services/Cache.service';
import { EventService } from 'src/app/services/Event.service';
import { TrackingService } from 'src/app/services/Tracking.service';
import { Filter } from 'src/app/types/Filter';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'va-event-overview',
  templateUrl: './event-overview.component.html',
  styleUrls: ['./event-overview.component.css'],
})
export class EventOverviewComponent implements OnInit, OnDestroy {
  private eventsSubscription = new Subject<void>();

  /* visible/filtered events */
  visibleEvents: crmikpk_participantstatus[] = [];

  /* Current events (uses default filter) */
  upcomingEvents: crmikpk_participantstatus[] = [];

  loading: boolean = true;

  //TODO: Filter übergeben
  currentFilter: string = defaultFilters[0].key;
  filters: Filter<crmikpk_participantstatus>[] = defaultFilters;
  error: string | null = null;
  constructor(
    private eventService: EventService,
    private cacheService: CacheService,
    private trackingService: TrackingService,
    private appInsights:AppInsightsService
  ) {}

  onFilterEvents(selectedFilter: Filter<crmikpk_participantstatus>) {
    this.currentFilter = selectedFilter.key;
    this.loading = true;
    this.displayEvents(() => setTimeout(() => this.displayEvents()));
  }

  async ngOnInit() {
    await this.cacheService.init();
    this.displayEvents();
    this.trackingService.init().then(() => {
      this.trackingService.track({
        eventInfo: {
          eventType: 'Page',
          eventName: `Load Page`,
        },
        attributes: {
          pageName: 'Veranstaltungen',
          pageUrl: window.location.href,
          pageContentId: 'IK.Veranstaltungen',
          pageInstanceName: window.location.origin,
          customECommerceParameter: {
            48: 'va-event-overview',
            49: environment.app.VERSION,
            50: environment.app.NAME,
          },
        },
      });
      this.appInsights.trackPageView(
        'Veranstaltungen',
        window.location.href
      );
    });
  }

  private isPastFilter() {
    return (
      this.currentFilter === 'Participated' || this.currentFilter === 'Gone'
    );
  }

  private getCurrentOrDefaultFilter() {
    return (
      this.filters.find((f) => f.key === this.currentFilter) || this.filters[0]
    );
  }

  private displayEvents(onComplete?: () => void) {
    const futureCachedEvents = this.cacheService.getEvents();
    const pastCachedEvents = this.cacheService.getEvents(this.isPastFilter());

    // if both caches are still valid continue to display cached events
    if (pastCachedEvents && futureCachedEvents) {
      const filteredEvents = this.getCurrentOrDefaultFilter().filterEvents(
        this.isPastFilter() ? pastCachedEvents : futureCachedEvents
      );

      this.visibleEvents = sortEventsByFilter(
        filteredEvents,
        this.getCurrentOrDefaultFilter()
      );

      // TODO: Filtering and sorting can be refactored when using APIM and not mock api
      const filterFutureEvents =
        defaultFilters[0].filterEvents(futureCachedEvents);
      this.upcomingEvents = sortEventsByFilter(
        filterFutureEvents,
        defaultFilters[0]
      );
      this.loading = false;
    } else {
      this.retrieveEvents(onComplete);
    }
  }

  private retrieveEvents(onComplete?: () => void) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let properties: { [key: string]: any }
    const requests: Observable<crmikpk_participantstatus[] | null>[] = [];

    const pastCachedEvents = this.cacheService.getEvents(true);
    const futureCachedEvents = this.cacheService.getEvents(false);

    const usePastEvents = pastCachedEvents === null && this.isPastFilter();
    // Check if caches are invalid and prepare refresh if cache is invalid or outdated
    if (usePastEvents) {
      requests.push(this.eventService.getPublishedEventsByContactID(true));
      properties = {"pastCachedEvents": false};
    }
    else{
      properties = {"pastCachedEvents": true};
    }

    // Check if caches are invalid and prepare refresh if cache is invalid or outdated
    if (futureCachedEvents === null) {
      properties = {"futureCachedEvents": false};
      requests.push(this.eventService.getPublishedEventsByContactID(false));
    }else{
      properties = {"futureCachedEvents": true};
    }
    this.appInsights.trackEvent("retrieveEvents", properties);

    if (requests.length > 0) {
      forkJoin(requests)
        .pipe(takeUntil(this.eventsSubscription))
        .subscribe({
          next: (value) => {
            if (value.length === 0) {
              // TODO: Fehlermeldung für Endnutzer anpassen
              this.error = 'Ein Fehler ist aufgetreten';
              this.loading = false;
              return;
            }

            if (requests.length === 1) {
              this.cacheService.saveEvents(value[0]!, usePastEvents);
              const filteredEvents =
                this.getCurrentOrDefaultFilter().filterEvents(value[0]!);
              if (usePastEvents) {
                this.visibleEvents = sortEventsByFilter(
                  filteredEvents,
                  this.getCurrentOrDefaultFilter()
                );
              } else {
                this.upcomingEvents = sortEventsByFilter(
                  filteredEvents,
                  defaultFilters[0]
                );
              }
            } else {
              this.cacheService.saveEvents(value[0]!, true);
              this.cacheService.saveEvents(value[1]!, false);

              const filteredEvents =
                this.getCurrentOrDefaultFilter().filterEvents(value[0]!);
              this.visibleEvents = sortEventsByFilter(
                filteredEvents,
                this.getCurrentOrDefaultFilter()
              );
              this.upcomingEvents = sortEventsByFilter(
                value[1]!,
                defaultFilters[0]
              );
            }
          },
          error: (error) => {
            this.error = error.message;
            console.error(error);
            this.appInsights.trackException(error, SeverityLevel.Critical );
          },
          complete: () => {
            this.loading = false;
            onComplete && onComplete();
          },
        });
    }
  }

  ngOnDestroy() {
    // unsubscribe from event subscription on dismount
    this.eventsSubscription.next();
    this.eventsSubscription.complete();
  }
}
