/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn:'root'
})
export class AppInsightsService {
  private appInsights: ApplicationInsights;

  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.instrumentationKey,
      },
    });
    this.appInsights.loadAppInsights();
  }
  trackEvent(name:string, properties?:{[key:string]:any}){
    this.appInsights.trackEvent({name, properties});
  }
  trackException(exception:Error, severityLevel?:SeverityLevel, properties?:{[key:string]:any}){
    this.appInsights.trackException({exception,severityLevel:severityLevel, properties});
  }
  trackPageView(name:string, uri:string){
    this.appInsights.trackPageView({name:name, uri:uri})
  }
}
