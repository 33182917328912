import packageInfo from 'package.json';

import { Config } from './config';

export const environment: Config = {
  name: 'development',
  production: false,
  test: false,

  eventsCache:{
    enabled : true,
    ttl: 300.000 //5 Minuten
  },

  /**
   * Trailing slash is prohibited
   */
  crm: {
    apiEndpoint: 'https://uiapimf5c41c8714ee.azure-api.net/crm',
    apiEndpointVersion: 'v9.2',
    apiVersion: '1.0.2',
    apiSubscription: 'dc2784f517b443c4a653a2e6bf67d5f9',
  },
  web_marketing: {
    registrationEndpoint:
      'https://uiapimf5c41c8714ee.azure-api.net/beta/web/marketing',
    registrationApiVersion: 'beta-1.0.1',
  },
  /**
   * Use Mock Data if activated ignores @apiEndpoint
   */
  useMockData: false,

  /**
   * Use Mock auth service
   */
  useMockAuthService: false,

  /**
   * Configuration for Azure B2C
   */
  auth: {
    authority:
      'https://b2cunioninvestmentdev.b2clogin.com/b2cunioninvestmentdev.onmicrosoft.com/B2C_1A_SIGNIN/v2.0',
    client_id: '9c0e5861-6b4d-45a6-b3ff-073240ce27d0',
    scope: 'openid profile',
    redirect_uri: 'https://veranstaltungen-dev.fe.union-investment.de/',
    post_logout_redirect_uri: 'https://portal-dev.institutional.union-investment.de/',
  },

  imagesEndpoint:
    'https://veranstaltungen-dev.fe.union-investment.de/assets/images',

  tracking: {
    enabled: true,
    domain: 'https://veranstaltungen-dev.fe.union-investment.de/',
    trackID: '808121318655954',
    trackDomain: 'daten.union-investment.de',
    contentID: 'IK',
  },

  instrumentationKey:'845194d1-9423-4fa4-9733-cdfb928a0ef7',

  app: {
    VERSION: packageInfo.version,
    NAME: packageInfo.name,
  },
};
